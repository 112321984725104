<template>
  <div class="container">
    <!-- 按条件筛选查找数据 -->
    <el-form ref="form" :inline="true" :model="formInline" small="size">
      <el-form-item label="随访模板名称：">
        <el-input
          v-model.trim="formInline.name"
          v-check-permission
          placeholder="请输入随访模板名称"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="queryData()"> 查询 </el-button>
        <el-button type="primary" @click="clearData()"> 重置 </el-button>
        <el-button
          v-if="$checkPermission('ADDTEM', $route)"
          type="primary"
          @click="dataOperation(null, 3)"
        >
          新增
        </el-button>
        <el-button type="primary" @click="$router.go(-1)"> 返回 </el-button>
      </el-form-item>
    </el-form>
    <el-table
      ref="multipleTable"
      v-loading="loading"
      :data="orderListData"
      border
      class="width-96"
    >
      <el-table-column type="index" label="序号" width="50" />
      <el-table-column prop="id" label="系统随访模板编码" align="center" />
      <el-table-column prop="name" label="随访模板名称" align="center" />
      <el-table-column prop="updatedBy" label="操作人" align="center" />
      <el-table-column prop="updatedAt" label="更新时间" align="center" />
      <el-table-column
        label="操作"
        show-overflow-tooltip
        width="200"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="mini"
            @click="dataOperation(scope.row, 0)"
          >
            查看
          </el-button>
          <el-button
            v-if="$checkPermission('EDITTMPLATE', $route)"
            type="text"
            size="mini"
            @click="dataOperation(scope.row, 1)"
          >
            编辑
          </el-button>
          <el-button
            v-if="$checkPermission('DELTEMPLATE', $route)"
            type="text"
            size="mini"
            @click="dataOperation(scope.row, 2)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页功能模块 -->
    <div class="padding-30">
      <el-pagination
        background
        :page-sizes="[10, 20, 50, 100, 200]"
        :current-page="pageInfo.page"
        :page-size="pageInfo.size"
        layout="->,total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
// import { mapActions} from 'vuex';

export default {
  name: 'FollowUpTemplate',
  data() {
    return {
      loading: false, // 给列表加上loading效果
      formInline: {
        name: '',
      },
      pageInfo: {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      },
      orderListData: [], // 服务列表
    };
  },
  computed: {},
  mounted() {
    this.getTemplateList();
  },
  methods: {
    // ...mapActions('basicDictionary', ['organPageQuery', 'organEdit']),
    // 分页方法调用
    handleSizeChange(currentPage) {
      this.pageInfo.size = currentPage;
      this.getTemplateList();
    },
    handleCurrentChange(currentPage) {
      this.pageInfo.page = currentPage;
      this.getTemplateList();
    },
    // 重置
    clearData() {
      this.formInline = {
        // 查询条件
        name: '',
      };
      this.pageInfo = {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      };
      this.getTemplateList();
    },
    queryData() {
      this.pageInfo.page = 1;
      this.getTemplateList();
    },
    // 分页查询用户套餐列表
    getTemplateList() {
      this.loading = true;
      const params = {
        ...this.formInline,
        orgCode: this.$route.query.orgCode,
        page: {
          done: true,
          pageIndex: this.pageInfo.page,
          pageSize: this.pageInfo.size,
        },
      };
      this.$api
        .interviewPageQuery(params)
        .then((res) => {
          this.orderListData = res.data.data;
          this.pageInfo.total = Number(res.data.total); // 获取总共有多少条数据
          this.loading = false;
        })
        .catch(() => {});
    },
    // 导出列表
    /**
     * 列表中数据的操作方法
     * 0 - 查看
     * 1 - 编辑
     * 2 - 删除
     */
    dataOperation(item, type) {
      if (type === 2) {
        this.open(item.id);
      } else {
        this.$router.push({
          name: 'templateDetail',
          query: {
            id: item !== null ? item.id : '',
            type: type,
            orgCode: this.$route.query.orgCode,
          },
        });
      }
    },
    open(id) {
      this.$confirm('确定删除该随访模板?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.delTemplate(id);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    // 删除模板
    delTemplate(id) {
      this.$api.interviewDelete({ id: id }).then((res) => {
        if (res.code === 0) {
          this.$message.success('删除模板成功');
          this.getTemplateList();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin-top: 20px;
}

.width-96 {
  width: 96%;
}

.pagination-padding-r {
  padding-right: 60px;
}

.demo-input-suffix {
  padding-top: 30px;
}

body .el-table th.gutter {
  display: table-cell !important;
}

.padding-30 {
  padding-right: 56px;
  margin-top: 10px;
}

.circle {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  border: solid rgb(0, 0, 0) 1px;
  border-radius: 50%;

  /* 宽度和高度需要相等 */
}

.flex {
  display: flex;
}

.orderStatic {
  padding: 10px 0;
}

.labelStyle {
  width: 8%;
  height: 35px;
  margin-right: 1px;
  font-size: 14px;
  text-align: center;
}

.active {
  color: #eee;
  background-color: #409eff;
}
</style>
